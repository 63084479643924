import React from "react";
import UserTable from "./UserTable";

export default function UserManagement() {
	return (
		<div>
			<UserTable />
		</div>
	);
}
