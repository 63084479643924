import React, { Component } from "react";
import StudentTable from "./StudentTable";

class StudentManagement extends Component {
  render() {
    return (
      <div>
        <StudentTable />
      </div>
    );
  }
}

export default StudentManagement;
