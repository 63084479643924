import React, { Component } from "react";
import TeacherTable from "./TeacherTable";

class TeacherManagement extends Component {
  render() {
    return (
      <div>
        <TeacherTable />
      </div>
    );
  }
}

export default TeacherManagement;
