import React from "react";
import Navbar from "../Navbar";

export default function HomeCover(props) {
  return (
    <div>
      <Navbar />
    </div>
  );
}
